import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs'
import {map} from 'rxjs/operators'
import {environment} from '../../environments/environment'
import {User} from './types'


@Injectable({
  providedIn: 'root'
})
export class UserService {
  /**
   * Publish and subscribe to the currently logged in user
   * here...
   */
  public currentUser$: ReplaySubject<User> = new ReplaySubject<User>(1)

  /**
   * Consumers should listen to this to get the latest user list.
   */
  public users: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([{} as any])

  constructor(
    private httpClient: HttpClient
  ) {
  }

  public getUsers(update?: boolean): Observable<User[]> {
    let url = `${environment.userServiceUrl}/users`
    if (update === true) {
      url += `?bust=${new Date().getTime()}`
    }
    return this.httpClient.get<User[]>(url)
      .pipe(
        map((users: User[]) => {
          this.users.next(users)
          return users
        })
      )
  }

  public getOffices(): Observable<any> {
    return this.httpClient.get<any[]>(`${environment.userServiceUrl}/data/offices`)
  }

  public getUser(sId: string): User {
    return this.users.value.find((user: User) => user.sId === sId) || {} as any
  }

}
