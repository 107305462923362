import {registerLocaleData} from '@angular/common'
import {provideHttpClient, withInterceptors} from '@angular/common/http'
import localeFrExtra from '@angular/common/locales/extra/fr'
import localeFr from '@angular/common/locales/fr'
import {APP_INITIALIZER, NgModule} from '@angular/core'
import {
  LuxonDateAdapter,
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
  MAT_LUXON_DATE_FORMATS
} from '@angular/material-luxon-adapter'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import {MatPaginatorIntl} from '@angular/material/paginator'
import {BrowserModule} from '@angular/platform-browser'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {SpbPaginatorSv, ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {authInterceptor} from './application/auth.interceptor'
import {responseInterceptor} from './application/response.interceptor'
import {WINDOW_PROVIDERS} from './application/window.provider'
import {XLSX_PROVIDERS} from './application/xlsx.provider'
import {SpbCommonModule} from './common/common.module'
import {ConfigService} from './services/config.service'

registerLocaleData(localeFr, 'fr', localeFrExtra)


@NgModule({
  declarations: [
    AppComponent
  ],
  exports: [],
  bootstrap: [AppComponent], imports: [BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SpbCommonModule,
    ThemeModule], providers: [
    WINDOW_PROVIDERS,
    XLSX_PROVIDERS,
    {
      provide: APP_INITIALIZER,
      useFactory: (s: ConfigService) => () => s.bootstrap(),
      deps: [ConfigService],
      multi: true
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'always',
        hideRequiredMarker: true,
        appearance: 'outline'
      }
    },
    {provide: MAT_DATE_LOCALE, useValue: 'sv-SE'},
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS},
    {provide: MatPaginatorIntl, useClass: SpbPaginatorSv},
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useValue: {useUtc: true, firstDayOfWeek: 1}
    },
    provideHttpClient(withInterceptors([authInterceptor, responseInterceptor]))
  ]
})
export class AppModule {
}
