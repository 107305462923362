import {Component, Input} from '@angular/core'

@Component({
  selector: 'spb-file-item',
  templateUrl: './file-item.component.html',
  styleUrls: ['./file-item.component.scss']
})
export class FileItemComponent {

  @Input() label: string

  @Input() stringValue: string

  @Input() numberValue: number

  @Input() dateValue: number
}
