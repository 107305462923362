import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {FormsModule} from '@angular/forms'
import {RouterModule} from '@angular/router'
import {MatExpansionModule} from '@angular/material/expansion'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import {SeverityDirective} from '../application/severity.directive'
import {UserInfoDirective} from '../application/user-info.directive'
import {HeaderComponent} from './header/header.component'
import {FileItemComponent} from './file-item/file-item.component'
import {UserInfoComponent} from './user-info/user-info.component'
import {StatisticsComponent} from './statistics/statistics.component'
import {CustomerNoDirective} from './directives/customer-no/customer-no.directive'
import {MatIconModule} from '@angular/material/icon'
import {MatMenuModule} from '@angular/material/menu'
import {MatFormFieldModule} from '@angular/material/form-field'
import {MatButtonModule} from '@angular/material/button'
import {MatInputModule} from '@angular/material/input'

@NgModule({
  declarations: [
    HeaderComponent,
    FileItemComponent,
    SeverityDirective,
    UserInfoComponent,
    CustomerNoDirective,
    UserInfoDirective,
    StatisticsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatExpansionModule,
    MatIconModule,
    MatMenuModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    ThemeModule
  ],
  exports: [
    HeaderComponent,
    FileItemComponent,
    SeverityDirective,
    UserInfoDirective,
    UserInfoComponent,
    StatisticsComponent,
    CustomerNoDirective
  ]
})
export class SpbCommonModule {
}
