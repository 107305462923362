import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {authGuard} from './application/auth.guard'
import {UserResolver} from './application/user.resolver'

export const routes: Routes = [
  {
    path: 'base',
    loadChildren: () => import('./base/base.module').then((m) => m.BaseModule),
    canActivate: [authGuard],
    canMatch: [authGuard],
    resolve: {
      users: UserResolver
    }
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule)
  },
  {
    path: '**',
    redirectTo: '/home',
    pathMatch: 'full'
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false, useHash: true,
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
