import {FactoryProvider, InjectionToken} from '@angular/core'
import * as XLSX from 'xlsx'
import {XLSX$Utils} from 'xlsx'

/**
 * Defines and exports an injection token that replaces the browser
 * window object.
 */
export const XLSX_PROVIDER = new InjectionToken<XLSX$Utils>('xlsx')

/**
 * The provider
 */
export const xlsxProvider: FactoryProvider = {
  provide: XLSX_PROVIDER,
  useFactory: () => XLSX
}

/**
 * The exported provider(s). We only have one. This is "provided"
 * in app.component.
 */
export const XLSX_PROVIDERS = [
  xlsxProvider
]
