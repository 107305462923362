import {Injectable} from '@angular/core'
import {BehaviorSubject, Observable} from 'rxjs'
import {HttpClient} from '@angular/common/http'
import {map} from 'rxjs/operators'
import {environment} from '../../environments/environment'
import {
  ApplicationConfig,
  DefaultApplicationConfiguration,
  Defect,
  File,
  FileStatus
} from './types'

@Injectable({
  providedIn: 'root'
})
export class DefectService {

  /**
   * The currently loaded FILES, only files, no friggin defects
   */
  public currentFiles: BehaviorSubject<File[]> = new BehaviorSubject<File[]>([])
  /**
   * The currently loaded DEFECTS, only defects but all defects, consumers may filter on ItemId
   * to distinguish different files (huvudakt, underakt etc.).
   */
  public currentDefects: BehaviorSubject<Defect[]> = new BehaviorSubject<Defect[]>([])
  /**
   * Make application types available to others through a subscription.
   * TODO: Move this to application service.
   *
   */
  readonly applicationConfig: ApplicationConfig = DefaultApplicationConfiguration
  public applicationTypes: BehaviorSubject<ApplicationConfig> = new BehaviorSubject<ApplicationConfig>(this.applicationConfig)
  /**
   * The files that we hold to make updates on and then publish on the
   *
   */
  private pCurrentFiles: File[]
  /**
   * The defects in private that we can manipulate (get/set/update) and publish.
   */
  private pCurrentDefects: Defect[] = []

  constructor(
    private httpClient: HttpClient
  ) {
    const url = `${environment.defectServiceUrl}/types?bust${new Date().getTime()}`
    this.httpClient.get<ApplicationConfig>(url)
      .subscribe({
        next: ((config: ApplicationConfig) => {
          this.applicationTypes.next(config)
        })
      })
  }

  /////////////////////////////////////////////////////
  // Only HTTP below this line
  /////////////////////////////////////////////////////

  /**
   * Get a defect by Id, only returns stored defects.
   */
  public getDefect(defectId: string): Defect {
    return this.pCurrentDefects.find((d) => d.defectId === defectId)
  }

  /**
   * Get a specific file that is already loaded
   */
  public getLoadedFile(itemId: string): File {
    return this.pCurrentFiles.find((f) => f.itemId === itemId)
  }

  /**
   * Get all defects (that are already loaded) that belong to a spefic file.
   */
  public getDefectsForFile(fileRef: string): Defect[] {
    return this.pCurrentDefects.filter((d) => d.fileRef === fileRef)
  }

  /**
   * Update an existing defect
   */
  public updateDefect(fileId, defect: Defect, resolve: boolean): Observable<Defect> {
    let query = ''
    if (typeof resolve !== 'undefined') {
      query = `?resolve=${resolve}`
    }
    const url = `${environment.defectServiceUrl}/defects/${fileId}/${defect.defectId}${query}`
    return this.httpClient.put<Defect>(url, defect).pipe(
      map((newDefect: Defect) => {
        this.pCurrentDefects = this.pCurrentDefects.map((d) => {
          if (d.defectId === defect.defectId) {
            return newDefect
          }
          return d
        })
        this.currentDefects.next(this.pCurrentDefects)
        return newDefect
      })
    )
  }

  /**
   * Add a defect
   */
  public addDefect(itemId: string, defect: Defect): Observable<Defect> {
    const url = `${environment.defectServiceUrl}/defects/${itemId}`
    return this.httpClient.put<Defect>(url, defect).pipe(
      map((newDefect: Defect) => {
        this.pCurrentDefects.push(newDefect)
        this.currentDefects.next(this.pCurrentDefects)
        return newDefect
      })
    )
  }

  /**
   * Retrieves all files that are not arrived.
   */
  public getCreatedFiles(): Observable<any> {
    const url = `${environment.defectServiceUrl}/files?status=${FileStatus.CREATED}`
    return this.httpClient.get<any>(url)
  }

  /**
   * Retrieves all files that are not arrived.
   */
  public getFileIds(): Observable<any> {
    const url = `${environment.defectServiceUrl}/files`
    return this.httpClient.get<any>(url)
  }

  /**
   * Fetch a file (akt) from the server this returns an array with all items
   * matching the fileId, that is both files and defectTypes.
   */
  getFile(fileId: string): void {
    const url = `${environment.defectServiceUrl}/files/${fileId}`
    this.httpClient.get<Array<File | Defect>>(url)
      .subscribe({
        next: (result: Array<File | Defect>) => {
          const files = result.filter((file) => !file.defectId).sort((a: File | Defect, b: File | Defect) => a.timeStamp - b.timeStamp)
          this.pCurrentFiles = files as File[]
          this.currentFiles.next(this.pCurrentFiles)

          const defects = result.filter((file) => file.defectId)
          this.pCurrentDefects = defects as Defect[]
          this.currentDefects.next(this.pCurrentDefects)
          return result
        }
      })
  }

  /**
   * Clears the current set of files/defects
   */
  public resetFilesAndDefects(): void {
    this.currentFiles.next([])
    this.currentDefects.next([])
  }

  /**
   * Fetch a specific ITEM from the database
   */
  getItem(itemId: string): Observable<any> {
    const url = `${environment.defectServiceUrl}/items/${itemId}`
    return this.httpClient.get<any>(url)
  }

  /**
   * File arrived/not arrived as called by the bulk reg component
   */
  public arriveFileStatus(fileId: string, version: number, status: FileStatus, file: File): Observable<any> {
    const url = `${environment.defectServiceUrl}/files/${fileId}/${version}/status/${status}`
    return this.httpClient.put<string>(url, file)
  }

  /**
   * Updates the status of a file
   */
  public updateFile(file: File): void {
    const url = `${environment.defectServiceUrl}/files/${file.itemId}/${file.version}/status/${file.fileStatus}`
    this.httpClient.put<any>(url, file).subscribe({
      next: () => {
        this.getFile(file.fileId)
      }
    })
  }

  /**
   * Creates a new file in the backend.
   * If no file supplied a dummy file is created
   */
  public createFile(file?: File): Observable<File> {
    const url = `${environment.defectServiceUrl}/files`
    return this.httpClient.put<File>(url, file)
  }

  public getStatistics(): Observable<any> {
    const url = `${environment.defectServiceUrl}/statistics` //  + '?bust=' + new Date().getTime()
    return this.httpClient.get<any>(url)
  }

  public deleteFile(id: string): Observable<any> {
    const url = `${environment.defectServiceUrl}/files/${id}` // + '?bust=' + new Date().getTime()
    return this.httpClient.delete<any>(url)
  }
}
