import {inject} from '@angular/core'
import {Router} from '@angular/router'
import {Observable} from 'rxjs'
import {LoggedInState, ConfigService} from '../services/config.service'
import {map} from 'rxjs/operators'

export const authGuard = (): Observable<boolean | Promise<boolean>> => {
  const router = inject(Router)
  const service: ConfigService = inject(ConfigService)

  return service.logInState$.pipe(
    map((state: LoggedInState) => {
      return state.loggedIn ? true : router.navigate(['/login'])
    })
  )
}
