<div class="spb-holder">

  <div class="printing">
    <spb-header></spb-header>
    <router-outlet></router-outlet>
  </div>

  <div [style.flex]="1"></div>

  <div class="printing">
    <spb-footer
      (logoutUser)="configService.logout()"
      [license]="true"
      [token]="(configService.accessToken$ | async)"
      [version]="version"
      copyrightYears="2020-2023"
    ></spb-footer>
  </div>
</div>
