/**
 * This file is nothing more than a bunch if types, interfaces etc. that
 * are use all over the place
 */

/**
 * Describes a user, will be refactored.
 */
export interface User {
  /**
   * The database Id
   */
  itemId: string
  /**
   * Typically s-ID (s209856)
   */
  sId: string

  /**
   * Personnummer
   */
  sub: string

  /**
   * Full name like Daniel Bergdahl
   */
  name: string

  phone?: string

  pet?: string
  /**
   * An array of roles, like "admin", "credit"...
   */
  roles: string[]

  /**
   * Silly indicator that I do not remember why I added.
   */
  index?: number

  /**
   * We can have an office
   */
  office?: string

  /**
   * Emails is good and should be mandatory
   */
  email?: string

  /**
   * We can have a user type, for example employee, support or developer.
   */
  userType?: string
}

export interface Role {
  /**
   * The display name.
   */
  name: string

  /**
   * The actual key to use in the user object.
   */
  key: string

  /**
   * A number representation of the role key, lower value higher rights.
   */
  value?: number

  /**
   * Description (only in RoleFromDatabase)
   */
  roleDescription?: string

  /**
   * only in RoleFromDatabase
   */
  itemId?: string
  roleKey?: string
  roleName?: string
  serviceName?: string
  serviceUrl?: string
  timeStamp?: number
  version?: number
}

export enum DefectActionType {
  EDIT,
  RESOLVE
}

export interface DefectAction {
  defectId: string
  action: DefectActionType
}

/**
 * The severity code and name.
 */
export interface Severity {

  id: SEVERITY_ERROR_ID

  name: string
}

/**
 *
 */
export interface DefectType {

  id: number

  severity: Severity

  description: string
}

export interface Defect {
  /**
   * The id of the file that this belongs to
   */
  fileId: string

  /**
   * A reference to the exact file this defect belongs to
   */
  fileRef: string

  /**
   *
   */
  defectId: string

  /**
   *
   */
  defectType: DefectType

  /**
   *
   */
  defectStatusList: Array<DefectStatusType>

  /**
   *
   */
  defectStatus: DefectStatus

  /**
   * This is the latest note
   */
  note?: string

  /**
   * Time stamp when this defect was created.
   */
  timeStamp?: number

  /**
   * A file object representing the base data.
   */
  file?: File
}

/**
 * The status of a file
 */

export const enum FileStatus {
  CREATED,
  ARRIVED,
  COMPLETE
}

/**
 * The slightly spurious list of file status indicators.
 */
export const enum FileStatusPosition {
  CREATED,
  ARRIVED,
  HAS_DEFECT,
  HAS_ERROR,
  HAS_SEVERE_ERROR,
  COMPLETE
}


/**
 * Pretty stupid to call it "File" but that is what an "akt" is.
 */
export interface File {

  /**
   * The unique ID in the database. It is required but not when we create
   * new items.
   */
  itemId?: string

  /**
   * This represents a loan or a security.
   */
  fileId: string

  /**
   * The "type" as received from Kerne/SBAB
   * 6 = generell säkerhet
   * 2 = Krediter och Byggnadskreditiv vid nyupplägg
   * 3 = Krediter och Byggnadskreditiv vid höjning
   * 5 = "grar"
   * 9 = Kreditkort.
   */
  sourceType?: string

  /**
   * The type of collateral, Fastighet, Bostadsrätt, Borgen, Företagshypotek, Övrig or Ingen Uppgift
   */
  collateralType?: string
  /**
   * Beskrivning av säkerhet (Ex Fastighetsbeteckning)
   */
  propertyName?: string

  /**
   * "Pantsättare" something we have made up.
   */
  collateralIdentity?: string

  /**
   * Namn (Kontohavare/Pantsättare)
   */
  customerName?: string

  /**
   * Kontohavare/Pantsättare (personnr, samordningsnr, orgnr.)
   */
  customerNumber?: string

  /**
   * Date, Utbetalningsdag
   */
  timeStamp?: number

  /**
   * Ändringsdag
   */
  changeDate?: number

  /**
   * Office, Kontor
   */
  office?: string

  /**
   * Responsible, Ansvarig
   */
  responsible?: string

  /**
   * Created, Arrived, Complete
   */
  fileStatus: FileStatus

  /**
   * The version in the database.
   */
  version?: number

  /**
   * This is a "fake" property that makes it possible to
   * distinguish files from defectTypes when we we get mixed
   * results. This will always be unset in files and always present
   * in defectTypes.
   */
  defectId?: string

  /**
   * If this file has ever had a defect. false if no defects
   * true if defect has ever existed.
   */
  pristine?: boolean

  /**
   * Attach defects to the file for reporting purposes.
   */
  defects?: Defect[]
}

/**
 * The severity codes.
 */

export enum SEVERITY_ERROR_ID {
  META_SEVERITY = -1, // Used for defectTypes that are not defectTypes.
  SEVERE_ERROR = 0, // Allvarligt fel.
  ERROR = 1, // Fel
  DEFECT = 2 // Brist.
}

/**
 * Meta defect id codes.
 */

// eslint-disable-next-line no-shadow
export const enum DEFECT_META_STATUS_ID {
  FILE_CREATED = 97,
  FILE_RECEIVED = 98,
  FILE_COMPLETE = 99,
  UN_ARRIVED = 100,
  UN_COMPLETE = 101,
}

/**
 * A defect can have status as enumerated
 */

// eslint-disable-next-line no-shadow
export enum DefectStatus {
  CREATED,
  UPDATED,
  RESOLVED,
}


/**
 * Defect status type is used for history list.
 */
export interface DefectStatusType {

  /**
   * Current status of defect.
   */
  defectStatus: DefectStatus

  /**
   * Unix timestamp
   */
  date: number

  /**
   * // Typically S-number or personnummer.
   */
  userId: string

  /**
   *
   */
  note?: string

}

/**
 * The application configuration as received from the backend.
 */
export interface ApplicationConfig {
  /**
   * The defect types.
   */
  defectTypes: DefectType[]

  /**
   * Available roles
   */
  roles: Role[]

  /**
   * Available defects per role.
   */
  availableDefects: AvailableDefects

  /**
   * List of available offices.
   */
  offices: string[]
}

/**
 * Available defects as returned from los backend.
 */
export interface AvailableDefects {
  admin: number[]
  credit: number[]
  internalSupport: number[]
  customerSupport: number[]
  office: number[]
}

/**
 * Default config, created 2020-03-03, update from time to time. When the backend changes
 *
 */
export const DefaultApplicationConfiguration: ApplicationConfig =
  {
    defectTypes: [{id: 0, severity: {id: 0, name: 'Allvarligt fel'}, description: 'Beslutsinstans'}, {
      id: 1,
      severity: {id: 0, name: 'Allvarligt fel'},
      description: 'Fordringshandling -Underskrift saknas'
    }, {id: 2, severity: {id: 0, name: 'Allvarligt fel'}, description: 'Kundkännedom'}, {
      id: 3,
      severity: {id: 0, name: 'Allvarligt fel'},
      description: 'Akt ej levererad'
    }, {id: 4, severity: {id: 0, name: 'Allvarligt fel'}, description: 'Säkerhet - Pantsättning felaktig'}, {
      id: 5,
      severity: {id: 2, name: 'Brist'},
      description: 'Denuntiationshandling / Fastighetsägarförklaring'
    }, {id: 6, severity: {id: 2, name: 'Brist'}, description: 'Företagsinteckning'}, {
      id: 7,
      severity: {id: 2, name: 'Brist'},
      description: 'GB/FDS/FIR-utdrag/Lagfart'
    }, {id: 8, severity: {id: 2, name: 'Brist'}, description: 'Grupplivförsäkring -Bekräftelse saknas'}, {
      id: 9,
      severity: {id: 2, name: 'Brist'},
      description: 'Pantbrev'
    }, {id: 10, severity: {id: 2, name: 'Brist'}, description: 'Pantbrev förkommet'}, {
      id: 11,
      severity: {id: 2, name: 'Brist'},
      description: 'Överhypotek'
    }, {id: 12, severity: {id: 1, name: 'Fel'}, description: 'Ansvarsförbindelse'}, {
      id: 13,
      severity: {id: 1, name: 'Fel'},
      description: 'Ansökan kontokredit'
    }, {id: 14, severity: {id: 1, name: 'Fel'}, description: 'Beslutsunderlag'}, {
      id: 15,
      severity: {id: 1, name: 'Fel'},
      description: 'Brandförsäkringsbevis'
    }, {id: 16, severity: {id: 1, name: 'Fel'}, description: 'Brfs Årsbokslut ej granskat'}, {
      id: 17,
      severity: {id: 1, name: 'Fel'},
      description: 'Inkomst'
    }, {id: 18, severity: {id: 1, name: 'Fel'}, description: 'Kreditansökan från EKP'}, {
      id: 19,
      severity: {id: 1, name: 'Fel'},
      description: 'Kvitto inlösta lån'
    }, {id: 20, severity: {id: 1, name: 'Fel'}, description: 'Registreringsbevis'}, {
      id: 21,
      severity: {id: 1, name: 'Fel'},
      description: 'Stämmer ej mot systemet'
    }, {id: 22, severity: {id: 1, name: 'Fel'}, description: 'Villkorsbilaga ej levererad'}, {
      id: 23,
      severity: {id: 1, name: 'Fel'},
      description: 'Villkorsförändring'
    }, {id: 24, severity: {id: 0, name: 'Allvarligt fel'}, description: 'Värdering'}, {
      id: 25,
      severity: {id: 1, name: 'Fel'},
      description: 'Ändringsanmälan'
    }, {id: 26, severity: {id: 1, name: 'Fel'}, description: 'Övrigt'}, {
      id: 97,
      severity: {id: -1, name: 'Aktstatus'},
      description: 'Akt skapad'
    }, {id: 98, severity: {id: -1, name: 'Aktstatus'}, description: 'Akt ankommen'}, {
      id: 99,
      severity: {id: -1, name: 'Aktstatus'},
      description: 'Akt komplett'
    }, {id: 100, severity: {id: -1, name: 'Aktstatus'}, description: 'Akt ej ankommen'}, {
      id: 101,
      severity: {id: -1, name: 'Aktstatus'},
      description: 'Akt ej komplett'
    }],
    roles: [{name: 'Administratör', key: 'admin', value: 0}, {
      name: 'Kredit',
      key: 'credit',
      value: 1
    }, {name: 'Internstöd', key: 'internalSupport', value: 2}, {
      name: 'Kundstöd',
      key: 'customerSupport',
      value: 3
    }, {name: 'Kontor', key: 'office', value: 4}],
    availableDefects: {
      admin: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 97, 98, 99, 100, 101],
      credit: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26],
      internalSupport: [5, 6, 7, 8, 9, 10, 11, 23, 25],
      customerSupport: [23, 25],
      office: []
    },
    offices: ['Borrby', 'Kivik', 'Kristianstad', 'Malmö', 'Simrishamn', 'Tomelilla', 'Ystad', 'Lomma', 'Digtal', 'Kundstöd']
  }
